.accordion {
    .accordion-item {
        background: none;
        border: 0px solid;
    }
    .accordion-body {
        padding: 0 1rem;
    }
    .accordion-header {
        @include heading_beatrice();
        color: #fff;
        .accordion-button {
            background: none;
            border: 0px solid;
            color: #fff;
            box-shadow: none;
            padding: 1rem;
        }
        
        .accordion-button:not(.collapsed)::after {
            transform: rotate(0deg);
            background-size: 1.7rem;
            display: none;  // hide the arrow
        }

        .accordion-button::after {
            display: none;  // hide the arrow
            width: 1.7rem;
            height: 1.7rem;
            background-size: 1.7rem;
            background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 11L18.243 15.243L16.828 16.657L14 13.828L11.172 16.657L9.757 15.243L14 11Z' fill='url(%23paint0_linear_2_6)'/%3E%3Cpath d='M14 4C19.5228 4 24 8.47715 24 14H28C28 6.26801 21.732 0 14 0L14 4ZM4 14C4 8.47715 8.47715 4 14 4L14 0C6.26801 0 0 6.26801 0 14L4 14ZM14 24C8.47715 24 4 19.5228 4 14L0 14C0 21.732 6.26801 28 14 28V24ZM14 28C21.732 28 28 21.732 28 14H24C24 19.5228 19.5228 24 14 24V28Z' fill='url(%23paint1_linear_2_6)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2_6' x1='18.243' y1='13.8285' x2='9.757' y2='13.8285' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234776E6'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_2_6' x1='26' y1='14' x2='2' y2='14' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234776E6'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            transform: rotate(180deg);
        }
        
    }
}

.eligibility{
    @extend .accordion;
    background-color: #3C3044 ;
    padding: 1rem;
    .accordion-body {
        p {
            color: #909193;
            font-family: $primary-font;
            a {
                color: #909193;
            }
        }
    }
}

.registrationComplete{
    border: none;
    background: none;
    img{
        border-radius: 40px;
    }
    position: relative;
    .registration_label {
        position: absolute;
        right: -1.5rem;
        label {
            background: #FFA0FB;
            border-radius: 4px;
            transform: rotate(6deg);
            padding: 0 1rem;
            display: flex;
            align-items: center;
            color: #200D40;
            font-family: BeatriceRegular;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            i {
                color: #200D40;
            }
        }
    }
}
.condition{
    border-radius: 0%;
    background: rgba(255, 255, 255, 0.75);
    padding: 1rem 1.8rem; 
    div{
        i{
            color: $color-theme;
        }
        p{
            color: $color-theme;
            font-size: 1rem;
            font-family: $primary-font;
            font-weight: 600;
        }
    }

}
.no-nft-detected{
    background: rgba(255, 255, 255, 0.75);
    border-radius: 20px;
    border: none;
    .card-body{
        .emoji{
            font-size: 3.865rem;
            transform: rotate(-18deg);
        }
        .card-text{
            font-family: $primary-font;
            color: $color-theme;
        }
    }
    .card-footer{
        background: rgba(26, 12, 35, 0.6);
        border: none;
        border-radius: 20px, 0 , 0 , 20px;
        .card-title{
            font-family: $primary-font;
            font-weight: 600;
            font-size: 1rem !important;
        }
    }
}
.no-sweet{
    font-family: $secondary-font;
        font-weight: 700;
    h3{
        font-size: 1.5rem;
    }
    h4{
        font-size: 1.25rem;
    }
}
.answer-poll{
    background: rgba(255, 255, 255, 0.25);
    border-radius: 12px;

    padding: 1rem 1.8rem; 
    div{
        i{
            color: $secondary-clr;
        }
        p{
            color: #fff;
            font-size: 1rem;
            font-family: $primary-font;
            font-weight: 600;
            margin-bottom: 0%;
            span{
                color: $secondary-clr;
            }
        }
    }

}
.feedback{
    h5{
        color: #00D1DE;
        font-family: $primary-font;
        font-weight: 700;
        font-size: 1.125rem;

    }
    p{
        font-family: $primary-font;
        font-weight: 400;
        font-size: 1rem;
    }
}
.shop{
    h3{
        color: #00D1DE;
        font-family: $primary-font;
        font-weight: 700;
        font-size: 1.125rem;
    }
    h4{
        color: #FCFCFC;
        font-family: Beatrice;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
    }
    p{
        font-family: $primary-font;
        font-weight: 400;
        font-size: 1rem;
        color: #C7C8C9;
    }
}
.save-date{
    font-family: $secondary-font;
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
}
.sub-paragraph{
    color: $secondary-clr;
    font-size: 1rem;
    font-family: $primary-font;
    font-weight: 400;
}

.functionality {
    @extend .accordion;
    .accordion .accordion-header button {
        font-size: 24px;
    }
}

.steps {
    display: flex;
    margin-bottom: 0.5rem;
    list-style: none;
    padding-left: 0;
    // justify-content: flex-end;
    a {
        margin-right: 2rem;
    }
    a:after {
        content: "";
        background: #FFA0FB;
        display: inline-block;
        height: 1px;
        position: absolute;
        width: 20px;
        top: 12px;
        margin-left: 0.3rem;
    }
    a:last-child:after {
        display: none;
    }
    a:before {
        display: inline-block;
        content: "";
        flex-shrink: 0;
        width: 1.7rem;
        height: 1.7rem;
        background-size: 1.7rem;
        background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="8" cy="8" r="7.5" fill="white" fill-opacity="0.25" stroke="%23FFA0FB"/%3E%3C/svg%3E');
        background-repeat: no-repeat;
    }
    a.complete:before {
        display: inline-block;
        content: "";
        width: 1.7rem;
        height: 1.7rem;
        background-size: 1.7rem;
        background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cg opacity="0.75"%3E%3Ccircle cx="8" cy="8" r="7.5" fill="%23FFA0FB" stroke="%23FFA0FB"/%3E%3Cpath d="M6.82322 9.76278L7.00001 9.93956L7.17679 9.76277L11.5961 5.343L11.9499 5.69656L7 10.6464L4.17155 7.818L4.525 7.46455L6.82322 9.76278Z" fill="white" stroke="white" stroke-width="0.5"/%3E%3C/g%3E%3C/svg%3E%0A');
    }
    a.active:before {
        display: inline-block;
        content: "";
        width: 1.7rem;
        height: 1.7rem;
        background-size: 1.7rem;
        background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="8" cy="8" r="7.5" fill="%23FFA0FB" stroke="white"/%3E%3C/svg%3E');
    }
}

@media only screen and (max-width: 990px) {
    .steps {
        a {
            margin-top: 2rem;
        }
        a:after {
            margin-top: 2rem;
        }
    }
}
.Shop-opensea{
    font-family: $primary-font;
    font-weight: 500;
    color: $secondary-clr;
    i{
        color: $secondary-clr;
    }
    &:hover{
        color: $secondary-clr;
    }
}