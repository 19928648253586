.drop{
    font-family: $primary-font;
    color: #fff;
    font-weight: 700;
    font-size: 1.25rem;
}

.feedback_box {
    h3 {
        font-family: Beatrice;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
        color: #FFFFFF;
    }

    .card {
        color: #FCFCFC;
        font-size: 16px;
        background: none;
        text-align: center;
        border: double 1px transparent;
        border-radius: 5px;
        background-image: linear-gradient(#1A0C23, #1A0C23), radial-gradient(circle at top left, #00D1DE, #FFA0FB);
        background-origin: border-box;
        background-clip: content-box, border-box;
    }
}

.notregistered-collection{
    .collection-card{
        .card-title{
            text-transform: capitalize;
            font-family: $primary-font;
            font-weight: 400;
            font-size: 1.125rem;
            padding-top: 1rem;
        }
        .card-text{
            font-size: 0.875rem;
        }
    }
}