.next-drop{
    // background-color: $secondary-clr;
    .next-drop-title{
        font-family: $primary-font;
        color: #fff;
    }
    h5{
        font-family: $primary-font;
        color: #fff;
        font-weight: bold;
        font-size: 20px;
    }
    .btn{
        position: absolute;
        right: 0;
        padding: 0.7rem;
        
        border-radius: 11.2px !important;
        color: #00D1DE;
        height: 80%;
        max-height: 100%;
        @include center();
        margin-top: 0.3rem;
        margin-right: 0.3rem;
        z-index: 3;
        border: 1.42857px solid #00D1DE;
    }

    .input-group-text{
        background-color: transparent;
    }

    .input-group{
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid #53495A;
        border-radius: 8px;
        .input-group-text{
            border: none;
            background: none;
            &:focus{
                font-family: $primary-font;
            }

            i{
                color: #FFFFFF;
                opacity: 0.6;
            }
        }
        .form-control{
            // background-color: rgba(255, 255, 255, 0.25);
            padding: 0.7rem 0.2rem;
            border: none;
            background: none;
            color: #FFFFFF;
            opacity: 0.6;
        }
    }
}