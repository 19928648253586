@font-face {
  font-family: Beatrice;
  src: url('./../fonts/beatrice/Beatrice Bold.otf');
}
@font-face {
    font-family: BeatriceRegular;
    src: url('./../fonts/beatrice/Beatrice Regular.otf');
}
$secondary-clr : #ECE75B;
$color-theme: #200D40;
$primary-font: 'Lexend Deca', sans-serif;
$secondary-font: Beatrice;
$texture-font: 'Chakra Petch', sans-serif;
$footer-font: 'inter', sans-serif;
body {
    i {
        vertical-align: middle;
    }
}
@mixin heading_beatrice {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.heading_lg {
    @include heading_beatrice;
    color: #fff;
    font-size: 28px !important;
}
.heading_h3 {
    @include heading_beatrice;
    color: #fff;
}
.heading_md {
    @include heading_beatrice;
    font-size: 18px !important;
    color: #fff;
}
.heading_sm {
    @include heading_beatrice;
    font-size: 16px !important;
    color: #fff;
}
.heading_normal {
    font-family: BeatriceRegular;
    color: #fff;
    font-size: 20px;
}
.heading_h4 {
    @include heading_beatrice;
    color: #C7C8C9;
}
.header_ld {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    text-decoration: none;
}
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nft-drops{
    .px-drops{
        color: #C7C8C9;
        font-size: 1.25rem;
        font-family: $secondary-font;
    }
    .get-more{
        color: #C7C8C9;
        font-size: 1rem;
        font-family: $primary-font;
    }
    .learn-more{
        // background-clip: text;
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
        //     -webkit-text-stroke-width: 1.01239px;
        //     -webkit-text-stroke-color: transparent;
            text-decoration: none;
            font-size: 1rem;
            font-family: $primary-font;
            background: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
    }
    .sub-headline{
        color: #C7C8C9;
        font-size: 0.75rem;
        font-family: $primary-font;
        font-weight: 700;
    }
}

.collection-card{
    background-color: transparent;
    border: none;
    .card-body{
        padding: 0;
    }
    .card-title{
        font-size: 2rem;
        font-family: $secondary-font;
        text-transform: unset;
    }
    .card-text{
        // color: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
        // background-image: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
        // background-clip: text;
        // -webkit-text-fill-color: transparent;
        font-family: $primary-font;
        font-weight: 400;
        font-size: 1rem;
        background: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
  }
  .walletRegister{
    p{
        font-family: $primary-font;
        font-weight: 400;
        font-size: 1rem;
        color: #fff;
    }
    .card{
        background: rgba(255, 255, 255, 0.25);
        border-radius: 20px;    
        // @include center();
        cursor: pointer;
        .card-title{
            color: #fff;
            font-family: $footer-font;
            font-weight: 600;
            font-size: 0.99rem;
        }
    }
}
hr{
    // color: #53495A;
    color: #d6bfe7;
    height: 1px !important;
}
@import 'index';
@import 'sponsor';
@import 'recommend';
@import 'nextdrop';
@import 'register';
@import 'claimday';
@import 'notregistered';

.header_area {
    .lg {
        font-family: Beatrice;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 130%;
        color: #FCFCFC;
    }
    .md {
        font-family: Beatrice;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
        color: #FCFCFC;
    }
}
.mt-9 { margin-top: 9rem; }
.mt-10 { margin-top: 10rem; }
.mt-11 { margin-top: 11rem; }
.mt-12 { margin-top: 12rem; }
.text-decoraton-none {
    text-decoration: none;
}
.ri-midx {
    font-size: 1.5em;
}

.gradient_txt {
    font-family: $primary-font;
    background: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-primary {
    background-color: #00D1DE !important;
    color: #200D40 !important;
}

.text-primary {
    color: #00D1DE !important;
}

.text-transform-normal {
    text-transform: unset !important;
}

.bg-primary-2 {
    background-color: #FFA0FB !important;
    color: #200D40 !important;
}

.bg-body-dark {
    background-color: #1A0C23 !important;
}

.border-light {
    border-color: #53495A !important;
}

.form-text {
    color: #200D40;
    margin-bottom: 0;
    i {
        color: #200D40;
    }
}
.input-error {
    border: 1px solid #FC4A54 !important;
    .form-control {
        color: #FC4A54 !important;
    }
}

.form-control-error {
    border: 1px solid #FC4A54 !important;
    color: #FC4A54 !important;
}

.text-warning-2 {
    color: $secondary-clr !important;
}

.drop_details_area {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 1rem;
    .card-text{
        color: #C7C8C9;
    }
}

.prev_link {
    text-decoration: none;
    font-family: Lexend Deca;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.ms-n5 {
    margin-left: -1.5rem;
}
.cursor-pointer {
    cursor: pointer;
}
.padleft-3 {
    padding-left: 3rem;
}