.recommend{
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 12px;
    box-sizing: border-box;
    .card-title{
        color: #1A0C23;
        font-size: 1.25rem;
        font-family: $secondary-font;
    }
}

@media only screen and (max-width: 768px) {
    .cardbtnbody {
        margin-left: 12.5%;
    }
}