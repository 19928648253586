.sponsor{
    .sponsor-content{
        h1{
            color: #fff;
            font-size: 2rem;
            font-family: $secondary-font;
            font-weight: 700;
        }
        form{
            background-color: $secondary-clr;
            padding: 1.5rem 2rem;
            .form-label{
                font-family: $primary-font;
                color: #200D40;
                font-size: 1rem;
            }
            .input-group-text{
                background-color: transparent;
            }
            .input-group{
                background-color: rgba(255, 255, 255, 0.25);
                border-radius: 8px;
                border: 1px solid #53495A;
                
                .input-group-text{
                    border: none;
                    color: #53495A;
                    background-color: rgba(255, 255, 255, 0.25);
                    border-radius: 8px;
                    background: none;
                    &:focus{
                        font-family: $primary-font;
                    }
  
                    i{
                        color: #53495A;
                    }
                }
                .form-control{
                background-color: rgba(255, 255, 255, 0.25);
                padding: 0.7rem;
                border: none;
                border-radius: 8px;
                background: none;

                }
            }
            .form-check{
                .form-check-input{
                    background-color: transparent;
                    color: $secondary-clr;
                    border: 2px solid #200D40;
                    border-radius: 4px;
                }
                .form-check-label{
                    font-family: $primary-font;
                    font-size: 1rem;
                    color: $color-theme;
                }
                :checked {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23200D40' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
                }
            }
            .error-box{
                .card{
                    background-color: $color-theme;
                    border-radius: 12px;
                    .card-body{
                        .card-text{
                            font-family: $primary-font;
                            font-weight: 400;
                            color: #fff;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}
.functionality{
    .gradiant_icon {
        background: -webkit-linear-gradient(180deg, #FFA0FB 0%, #00D1DE 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    div{
        h2{
            font-family: $primary-font;
            font-weight: 700;
            font-size: 1.125rem;
            color: #00D2FF;
        }
        p{
            font-family: $primary-font;
            font-size: 1rem;
            color: #C7C8C9;
            span{
                color: $secondary-clr;
            }
        }
        h4{
            font-family: $primary-font;
            font-weight: 600;
            color: #FCFCFC;
            font-size: 1rem;
        }
    }
    .instructions{
        font-family: $secondary-font;
        font-weight: 700;
        font-size: 1.25rem;
        color: #fff;

    }
}