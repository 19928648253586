* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --toastify-z-index:60000;
  --toastify-color-info: #200d3f;
  --toastify-text-color-light:#1a0c23;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #1A0C23;
}

i {
  color: #fff;
}

// -webkit-background-clip: text;
// 	-webkit-text-fill-color: transparent;
// 	-webkit-text-stroke-width: 1.01239px;
// 	-webkit-text-stroke-color: #4b370d;
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #53495A;
  opacity: 1;
  /* Firefox */
  font-size: 1rem;
  font-family: $primary-font;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container-fluid {
  padding: 0%;
}

.free-tshirt {
  position: relative;
  // overflow: hidden;
  display: block;

  .text-overlay {
    z-index: 10001;
    position: absolute;
    margin-top: -28%;
    background: #ECE75B;
    display: inline;
    left: 0;
    padding-top: 1rem;
    @include center();
    width: 102%;
    transform: matrix(1, -0.09, 0.07, 1, 0, 0);
    padding-left: 4%;
    padding-right: 4%;
    // word-break:break-all;
    // transform: rotate(-3.69deg);
    .overlay-bottom {
      position: absolute;
      bottom: -30px;
      right: 22px;
      color: #FCFCFC;
      opacity: 0.5;
    }
    p {
      color: #1A0C23;
      font-family: $secondary-font;
      font-weight: 700;
      font-size: 1.2rem;
      @include center();
      text-align: center;
    }

    .btn {
      position: absolute;
      bottom: -18px;
      left: 15px;
      background-color: #ECE75B;
      border-radius: 100px;
      font-family: $primary-font;
      font-size: 0.75rem;
      font-weight: 600;
    }
  }

  .card {
    height: 100vh;
    border: none;
    border-radius: 0 0 0 0;
    background-color: #312439;
    overflow: auto;
    overflow-x: hidden;

    // .img-fluid{
    //     height: 60vh;
    // }
    // .card-img-overlay{
    //     // position: absolute;
    //     bottom: 0 !important;
    //     top: 55% ;
    //     background-color: rgba(255, 255, 255, 0.75);;
    //     transform: rotateX(deg);
    // }
    .card-title {
      background-color: $secondary-clr;
      font-family: $primary-font;
      @include center();
      padding: 1rem 0;
      color: $color-theme;
      font-weight: 700;

      color: #1A0C23;

      i {
        color: #1A0C23;
      }
    }

    .card-body {
      .card-text {
        color: white;
        font-family: $primary-font;
        font-weight: 400;
        font-size: 1rem;

        &:nth-child(1) {
          // text-decoration: underline;
        }
      }

      

      .social-media-share {
        p {
          font-family: 'Epilogue', sans-serif;
          font-weight: 500;
          font-size: 0.9rem;
          line-height: 28px;
          cursor: pointer;

          i {
            color: #00D1DE;
          }
        }
      }
    }
  }
}

.footer-title {
  font-family: $footer-font;
  font-weight: 700;
  font-size: 0.9rem;
  color: #C7C8C9 !important;
  opacity: 0.5;
  @include center();
  align-items: flex-start;
}

.sponsor-card{
  border: double 1px transparent;
  border-radius: 12px;
  background-image: linear-gradient(#1A0C23, #1A0C23), radial-gradient(circle at top left, #00D1DE, #FFA0FB);
  background-origin: border-box;
  background-clip: content-box, border-box;
  .card-title{
    color: #C4C4C4;
    font-size: 1.25rem;
    font-family: $primary-font;
    font-weight: 700;
    &:nth-child(1){
      font-family: $secondary-font;
    }
  }
  .card-text{
    font-family: $primary-font;
    font-size: 1rem;
    color: rgba(233, 234, 234, 0.7);
  }
  .sub-headings{
    font-family: $primary-font;
    font-weight: 700;
  }
  .btn{
    padding: 0;
    font-family: $primary-font;
    font-size: 1rem;
    padding-bottom: 1rem;
    text-decoration: underline;
  }
}
.collection-card{
  background-color: transparent;
  border: none;
  .img_area {
    position: relative;

    .wallet_registered {
      position: absolute;
      bottom: 0rem;
      right: 2.75rem;
    
      i {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='15' fill='%231E2223' stroke='%2300DE9F' stroke-width='2'/%3E%3Cpath d='M14 19.172L23.192 9.979L24.607 11.393L14 22L7.636 15.636L9.05 14.222L14 19.172Z' fill='%2300DE9F'/%3E%3C/svg%3E");
        
        display: inline-block;
        content: "";
        flex-shrink: 0;
        width: 1.7rem;
        height: 1.7rem;
        background-size: 1.7rem;
        background-repeat: no-repeat;
        margin-right: 1rem;
      }
      p {
        font-size: 12px;
        color: #00DE9F;
        font-family: $primary-font;
      }
    }

    .not_registered {
      i {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='15' fill='%231E2223' stroke='%23FC4A54' stroke-width='2'/%3E%3Cpath d='M16 14.586L20.95 9.636L22.364 11.05L17.414 16L22.364 20.95L20.95 22.364L16 17.414L11.05 22.364L9.636 20.95L14.586 16L9.636 11.05L11.05 9.636L16 14.586Z' fill='%23FC4A54'/%3E%3C/svg%3E%0A");
      }
      p {
        color: #FC4A54;
      }
    }
  }

  .card-body{
    padding: 0;
  }
  .card-title{
    font-size: 2rem;
    font-family: $secondary-font;
  }
  .card-text{
      // color: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
      // background-image: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
      // background-clip: text;
      // -webkit-text-fill-color: transparent;
      background: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
}
.navbar-dark .navbar-toggler {
  border: 0px solid;
  box-shadow: 0 0 0 0;
}
.navbar-dark .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}

.bg-theme-purple {
  background-color: $color-theme !important;
}

.nft-drop{
  /* height: 90vh;
  overflow-y: scroll; */
  position: relative;

  .navbar{
    background-color: $color-theme !important;
    padding: 0.5rem 0;
    @include center();
  }
  .content{
    
    .heading{
      font-size: 2rem;
      font-family: $secondary-font;
    }
      
    .nav-link {
      color: #C7C8C9;
      font-size: 0.75rem;
      font-family: $primary-font;
      font-weight: 700;
      padding: 1rem;
    }
  }

  .content {
    /* height: 90vh;
    overflow-y: scroll; */

    .heading {
      font-size: 2rem;
      font-family: $secondary-font;
    }

    // .collection-card {
    //   background-color: transparent;
    //   border: none;

    //   .card-body {
    //     padding: 0;
    //   }

    //   .card-title {
    //     font-size: 2rem;
    //     font-family: $secondary-font;
    //   }

    //   .card-text {
    //     // color: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
    //     // background-image: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
    //     // background-clip: text;
    //     // -webkit-text-fill-color: transparent;
    //     background: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
    //     background-clip: text;
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //   }
    // }

    /* .sponsor-card {
      background-color: rgba(255, 255, 255, 0.75);
      ;

      .card-title {
        color: #1A0C23;
        font-size: 1.25rem;
        font-family: $primary-font;
        font-weight: 700;

        &:nth-child(1) {
          font-family: $secondary-font;
        }
      }

      .card-text {
        font-family: $primary-font;
        font-size: 1rem;
      }

      .sub-headings {
        font-family: $primary-font;
        font-weight: 700;
      }

      .btn {
        padding: 0;
        font-family: $primary-font;
        font-size: 1rem;
        padding-bottom: 1rem;
        text-decoration: underline;
      }
    } */

  }

  .register {
    background-color: $color-theme;
    position: fixed;
    bottom: 0;
    width: 67%;

    //   height: 15vh;
    z-index: 1000000;

    // right: 0;
    h5 {
      text-transform: uppercase;
      padding: 1rem;
      font-family: $secondary-font;
      font-size: 1rem;
    }

    .registration {
      display: flex;
      justify-content: space-between;
    }

    a:not(.backbtn), button.claim {
      color: #1E2223;
      background: #00D1DE;
      border-radius: 8px;
      padding: 1rem 2rem;
      text-decoration: none;
      font-size: 1.25rem;
      font-family: Beatrice;
      font-weight: 700;
      border-radius: 8px;
      width: 100%;
      text-align: center;
      i {
        color: #1E2223;
        vertical-align: middle;
      }
    }

    a.backbtn {
      color: #00D2FF;
      text-decoration: none;
      font-family: Beatrice;
      font-weight: bold;
    }

    .share {
      display: none !important;
    }

    .register-title {
      display: block;
    }

    button {
      background: none;
      border: none;
      color: #00D2FF;
      font-family: Beatrice;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }
}



.pxbtn {
  width: 230px;
  height: 55px;
  border: double 3px transparent;
  border-radius: 100px;
  background-image: linear-gradient(#1A0C23, #1A0C23), radial-gradient(circle at top left, #00D1DE, #FFA0FB);
  background-origin: border-box;
  background-clip: content-box, border-box;
  text-decoration: none;
  color: #fff;
  @include center();
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

/* #tns3 > .tns-item {
  width: calc(19.5%) !important;
} */
.tns-outer {
  position: relative;

  .tns-slide-active {
    text-align: center;
  }

  .tns-nav {
    float: right;
    right: 1rem;
    position: absolute;
    bottom: 9rem;
    opacity: 0.5;
    transform: rotate(-3.69deg);

    button {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      transform: rotate(-3.69deg);
      margin: 0 4px;
      padding: 0.25rem;
      border-radius: 50%;
    }

    .tns-nav-active {
      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      transform: rotate(-3.69deg);
    }
  }
}


footer {

  // position: fixed;
  // bottom: 0;
  // right: 0;
  // float: left;
  .sponsor-drop {
    font-family: $footer-font;
    font-weight: 400;
    text-decoration: none;
    border: 1px solid transparent;
    border-image: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
    border-image-slice: 1;
    border-radius: 100px;
    padding: 1rem;

    &::after {
      border-radius: 100px;
    }

    // margin-top: 1rem;
  }

  h5 {
    font-family: $footer-font;
    font-weight: 800;
  }

  .info {
    list-style: none;
    padding-left: 0;
    font-family: $footer-font;
    font-weight: 400;

    .info-item {
      padding-top: 0.1rem;
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
.text-warning {
  color: #F3C648 !important;
}

.desktop-only {
  display: block;
}
.mobile-only {
  display: none;
}

.drop_timer_box {
  background: $secondary-clr;
  position: fixed;
  width: 103%;
  z-index: 10001;
  p {
    color: #1A0C23;
    font-size: 16px;
  }

  label.timer-mini {
    background-color: #00D1DE;
    border-radius: 3rem;
    padding: 1rem 2rem;
    font-family: Beatrice;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: -2rem;
  }
}

.claim_timer_box {
  background: $secondary-clr;
  position: fixed;
  width: 70%;
  z-index: 10001;
  p {
    color: #1A0C23;
    font-size: 16px;
  }

  label.timer-mini {
    background-color: #00D1DE;
    border-radius: 3rem;
    padding: 1rem 2rem;
    font-family: Beatrice;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: -2rem;
  }
}

@media only screen and (max-width: 375px) {
  .free-tshirt .text-overlay{
    z-index: 90;
  }
}
@keyframes navcollapse {
  0% {
    height: 0vh;
  }
  25% {
    height: 25vh;
  }
  50% {
    height: 50vh;
  }
  75% {
    height: 75vh;
  }
  100% {
    height: 100vh;
  }
}
.navbar.navbar-dark {
  // position: fixed;
  // z-index: 10002;
  // width: 100%;
  // top:0;
  // right: 0;
  background-color: #1A0C23 !important;
  .nav_logo {
    // display: flex;
    // justify-content: space-between;
    // width: 100%;
    padding-top: 0.5rem;
  }
  .navbar-collapse{
    z-index: 10000001;
    position: absolute;
    top: 3.5rem;
    background: #1A0C23;
    height: 120vh !important;
    width: 100%;
    // transition: 0.5s ease-in-out;
    // animation: navcollapse 2s ease;
  }
  .navbar-nav {
    // height: 92vh;
    .navbar-form{
      display: block;
    }
    .nav-link {
      font-family: $footer-font;
      font-size: 20px;
      letter-spacing: 0.02em;
      border-bottom: 2px solid rgba(255, 255, 255, 0.25);
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;
      color: #fff;
      font-weight: 500;
    }
  }
}
@media only screen and (max-width: 990px) {
  .claim_timer_box {
    width: 100%;
  }

  .nft-drop .content .heading {
    margin-top: 1rem;
  }
  #tns3 > .tns-item {
    width: calc(19.25%) !important;
  }
  .t-shirt-card-body {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
  .navbar.navbar-dark {
    position: fixed;
    z-index: 10002;
    width: 100%;
    top:0;
    right: 0;
    background-color: #1A0C23 !important;
    .nav_logo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 0.5rem;
    }
  }
  body {
    margin-top: 70px;
  }
  .free-tshirt .text-overlay {
    width: 98%;
    p {
      padding: 0 1.1rem;
    }
  }

  

  .free-tshirt .card {
    height: auto;
    overflow: hidden;
  }

  .px-drops .content {
    height: auto;
    overflow-y: hidden;
    padding: 0 0.75rem;
  }
}
@media only screen and (min-width: 769px) {
  .tns-nav {
    bottom: 18rem !important;
  }
  .nav_logo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.5rem;
  }
  .nft-drop{
    height: 90vh;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 1280px) {
  .free-tshirt .text-overlay p {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 1100px) {
  .tns-nav {
    bottom: 9rem !important;
  }
}
@media only screen and (min-width: 1280px) {
  
}
@media only screen and (min-width: 1600px) {
  .free-tshirt .text-overlay {
    margin-top: -22%;
  }
}
@media only screen and (min-width: 1900px) {
  .free-tshirt .text-overlay {
    margin-top: -18%;
  }
}

@media only screen and (max-width: 768px) {
  .feedback-mobile .bgbox .ticket_img {
    right: 0px !important;
  }
  .feedback-mobile .bgbox {
    border-radius: 0px !important;
    background-color: #3C3044 !important;
  }
  #tns1>.tns-slide-active {
    width: auto !important;
  }
}
@media only screen and (max-width: 990px) {
  .free-tshirt .text-overlay p {
    font-size: 1rem;
  }
  .mobile-side-head {
    display: flex;
    align-items: center;
  }
  .px-drops .register {
    width: 104%;
    text-align: center;
    justify-content: center;

    .share {
      margin-top: 3.5rem;
      display: flex !important;
      p {
        i {
          color: #00D1DE;
        }
      }
    }

    .registration {
      justify-content: center;
    }

    .register-title {
      display: none;
    }

    a:not(.backbtn) {
      position: absolute;
      top: -35px;
      left: 51%;
      transform: translateX(-50%);
      border: 17px solid $color-theme;
      width: 85%;
      border-radius: 1.5rem;
    }

    a.backbtn {
      transform: translateX(-50%);
      width: 100%;
      position: absolute;
      top: 15px;
      left: 50%;
      color: #00D2FF;
      text-decoration: none;
      font-family: Beatrice;
      font-weight: bold;
    }
  }

  .drop-timer {
    position: fixed;
    width: 103%;
    z-index: 10001;
    top:60px;
  }
  .tns-outer {
    // margin-top: 2rem;
  }
}
@media screen and (min-width: 990)and (max-width: 1300) {
  .register{
    
    a{
      font-size: 1rem;
    }
  }
}

.feedback-mobile{
  i {
    color: #ECE75B;
  }
  .bgbox {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 12px;
    padding: 1.5rem 1rem 2rem 1rem;
    position: relative;
    .giveaway-survey {
      background: #00D1DE;
      border-radius: 8px;
      padding: 0.75rem 1.25rem;
      text-decoration: none;
      color: #1E2223;
      font-family: Beatrice;
      i {
        color: #1E2223;
        vertical-align: middle;
      }
    }
    .ticket_img {
      position: absolute;
      right: -15px;
      bottom: -20px;
    }
  }
  h3{
      color: #00D1DE;
      font-family: $primary-font;
      font-weight: 700;
      font-size: 1.125rem;
  }
  h4{
      color: #FCFCFC;
      font-family: $primary-font;
      font-weight: 400;
      font-size: 1rem;
  }
  p{
      font-family: $primary-font;
      font-weight: 400;
      font-size: 1rem;
      color: #C7C8C9;
  }
}
@media (min-width: 992px){
  .mx-auto{
    margin-right: 8% !important;
  }
}

.addeventatc {
  color: #1E2223 !important;
  background: #00D1DE !important;
  border-radius: 8px !important;
  font-family: Beatrice !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 28px !important;
  padding: 1rem 2rem !important;
  width: 100%;
  text-align: center;
  margin: 1rem auto;
  .addeventatc_icon {
    display: none;
  }
}

.how_to_claim {
  background-color: rgba(255, 255, 255, 0.15);
  padding: 1.75rem 1.5rem;
  margin-bottom: 2rem;
  border-radius: 12px;
  .list-group-item {
    background-color: transparent;
    border: none;
    padding:0;
    margin-top: 1.25rem;
    p {
      color: #C7C8C9;
    }
  }
}

.top_footer {
  a, h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.75);
  }
}


.previous-collection-card{
  background-color: transparent;
  border: none;
  .img_area {
    position: relative;

    .not_registered {
      i {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='15' fill='%231E2223' stroke='%23FC4A54' stroke-width='2'/%3E%3Cpath d='M16 14.586L20.95 9.636L22.364 11.05L17.414 16L22.364 20.95L20.95 22.364L16 17.414L11.05 22.364L9.636 20.95L14.586 16L9.636 11.05L11.05 9.636L16 14.586Z' fill='%23FC4A54'/%3E%3C/svg%3E%0A");
      }
      p {
        color: #FC4A54;
      }
    }
  }

  .card-body{
    padding: 0;
  }
  .card-title{
    font-size: 2rem;
    font-family: $secondary-font;
  }
  .card-text{
      // color: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
      // background-image: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
      // background-clip: text;
      // -webkit-text-fill-color: transparent;
      background: linear-gradient(90deg, #00D1DE 0%, #FFA0FB 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
}

.previous_drop_card {
  text-decoration: none;
  background: #25182E;
  .date_aside {
    background: #312439;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #C7C8C9;
      transform: rotate(180deg);
      writing-mode: vertical-lr;
      font-family: 'Lexend Deca';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      margin: 0;
    }
  }

  .drop_details_area {
    padding: 0;
    p {
      font-size: 14px;
    }
  }
  .bannerimg {
    background: linear-gradient(270.2deg, #513165 0.18%, rgba(49, 36, 57, 0) 99.84%);
  }

  .title {
    font-family: Lexend Deca;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #ECE75B;
  }

  .subtitle {
    font-family: Lexend Deca;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 16px;
    color: #FFFFFF;
  }


  .multi_sponsor_detail {
    a {
      border-bottom: 0px solid #fff !important
    }
    span {
      margin-right: 0.5rem;
    }
  }
  .multi_sponsor_detail span:last-child {
    display: none;
  }
}

.previous_drop_mobile_card {
  text-decoration: none;
  border: 0;
  background: #25182E;
  .card-top {
    border:0;
    background: linear-gradient(180deg, #513165 0.49%, #25182E 99.56%);
    padding-top: 1rem;
  }


  .title {
    font-family: Lexend Deca;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #ECE75B;
  }

  .subtitle {
    font-family: Lexend Deca;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
  }

  .previous-collection-card h5 {
    font-size: 1rem;
  }

  .drop_details_area {
    padding: 0;
    p {
      font-size: 14px;
    }
  }


  .date_aside {
    background: #312439;
    text-align: center;
    vertical-align: middle;
    
    p {
      color: #C7C8C9;
      margin-top: 0.75rem;
    }
  }
}

.multi_sponsors {
  display: flex;
  align-items: flex-end;
  justify-content: start;
  .sponsor_cnt {
    color: #fff;
    background-color: #1A0C23;
    border-radius: 50%;
    padding: 1rem 1.1rem;
    border: 4px solid #312439;
    margin-left: -1rem;
    font-family: Lexend Deca;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    text-decoration: none;
  }
}

.multi_sponsor_detail {
  a {
    color: #fff;
    font-size: 14px;
    margin-right: 0.25rem;
    text-decoration: none;
    border-bottom: 0.02rem solid #fff;
  }
  span {
    color: #fff;
    margin-right: 0.25rem;
  }
  
}

.multi_sponsor_detail span:last-child span {
  display: none;
}

.multi_sponsors_list {
  width: 100%;
  height: 100vh;
  background: #312439;
  overflow-y: scroll;
  // z-index: 100003;
  // padding: 2rem;

  .multi-sponsor-card {
    color: #FCFCFC;
    font-size: 16px;
    text-align: center;
    border-radius: 12px;
    background: #1A0C23;
    margin-top: 1rem;
    min-height: 200px;
    .card-body {
      padding-top: 1.75rem;
    }
    a:hover {
      color: #fff;
    }
  }
}
.multi_sponsors_list.rightaligned_box {
  height: 100vh;
  top: 9rem;
  background: #1A0C23;
  .multi-sponsor-card {
    background: #312439;
  }
}

.prev_multi_sponsors_list {
  
    // background: #312439 !important;

    .btn-close {
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36' height='36'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E") center/1em auto no-repeat;

    }
    .multi-sponsor-card {
      color: #FCFCFC;
      font-size: 16px;
      text-align: center;
      border-radius: 12px;
      background: #312439;
      margin-bottom: 1rem;
      min-height: 200px;
      .card-body {
        padding-top: 1.75rem;
      }
      a:hover {
        color: #fff;
      }
    }
}

.newsletter-form-submit.bordered_btn {
  border: 1px solid #00D2FF !important;
  font-size: 14px !important;
  font-family: BeatriceRegular;
  font-weight: normal;
}


.free-tshirt .card .card-body .drop_details_area .card-text {
  margin-bottom: 0.5rem;
}