.connectyourwallet{
    color: #fff;
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 2rem;
}
.sub-heading-connection{
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 1.125rem;
    color: #fff;
}
.exclusive-drop{
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 1.25rem;
    color: #fff;
}
.success-claim{
    font-family: $primary-font;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
}
.address-form{
    p{
        font-family: $primary-font;
        font-weight: 400;
        color: $secondary-clr;
        font-size: 0.875rem;
        i{
            color: $secondary-clr;
        }
    }
    .form-label{
        font-family: $primary-font;
        font-weight: 400;
        font-size: 1rem;
        color: #fff;
    }
    ::placeholder {
        color: #909193;
      }
    .form-control{
        background: rgba(255, 255, 255, 0.25);
        border: 1px solid rgba(233, 234, 234, 0.7);
        border-radius: 8px;
        padding: 0.6rem;
        color: #FCFCFC;
      }
    select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z' fill='rgba(233,234,234,1)'/%3E%3C/svg%3E") !important;
        background-position: calc(100% - 0.75rem) center !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 2rem !important;
        background-repeat: no-repeat !important;
        color: #909193;
    }
}
.expected-delivery{
    p{
        color: #fff;
        font-family: $primary-font;
        font-weight: 400;
        font-size: 1rem;
    }
}